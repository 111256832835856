<template>
  <FadeTransition :duration="200" v-show="show">
    <div
      class="notification-menu"
      v-infinite-scroll="loadMore"
      :infinite-scroll-disabled="busy"
      infinite-scroll-distance="10"
    >
      <template v-if="notifications.length">
        <span
          v-for="notification in notifications"
          :key="notification.item.id"
        >
          <div
            :class="{ 'row item': true, read: notification.read }"
            @click="updateBullet(notification)"
          >
            <span v-if="notification.read == false" class="bullet"></span>
            <div class="col-lg-2 pl-0 pr-0">
              <img class="img" :src="notification.item.icon" alt="">
            </div>
            <div class="col-lg-10 pr-0">
              <div>
                <p class="mb-0">{{ notification.item.body }}</p>
              </div>
              <div class="label-box align-items-end custom-height mt-2">
                <router-link v-if="notification.item.link" :to="notification.item.link" class="text_sm d-block link">
                  Ver más
                </router-link>
                <span class="text_xs btn-time mb-0">{{ $moment(notification.item.created_at).locale('es').fromNow().replace('hace', '') }}</span>
                <span class="i-btn i-btn-sm i-delete btn-delete mr-0" @click="deleteNotification(notification.item.id, notification.user_id)"></span>
              </div>
            </div>
          </div>
        </span>
      </template>
      <template v-else>
        <div>
          <p class="text-center mb-0 py-4">Sin notificaciones</p>
        </div>
      </template>
    </div>
  </FadeTransition>
</template>

<script>
import axios from 'axios';
import dashboard_api from '../dashboard_api.js';
import { mapMutations, mapState } from 'vuex';
import infiniteScroll from 'vue-infinite-scroll';
import { FadeTransition } from 'vue2-transitions';

export default {
  props: ['show'],
  components: { FadeTransition },
  directives: { infiniteScroll },
  data() {
    return {
      busy: true,
      pages: 0,
      count: 0
    }
  },
  computed: {
    ...mapState(["notifications", "selected_bot", "user"]),
  },
  watch: {
    selected_bot: {
      immediate: true,
      handler(val) {
        if (val.id) this.fetchNotifications(1);
      }
    }
  },
  methods: {
    ...mapMutations(['getNotifications', 'setDeleteNotification', 'setUpdateNotification', 'setNotificationBullet']),

    async updateBullet(notification) {
      if (!notification.read) {
        try {
          await dashboard_api.put(`notifications/update_status/${notification.item.id}/${notification.user_id}`, { read: true });
          this.setUpdateNotification(notification);
          this.setGlobalBulletNotification();
        } catch (error) {
          console.log("error update->", error)
        }
      }
      return;
    },
    async deleteNotification(notification_id, user_id) {
      try {
        await dashboard_api.put(`notifications/archived/${notification_id}/${user_id}`, { archived: true});
        this.setDeleteNotification(notification_id);
        this.setGlobalBulletNotification();

        this.$swal({
          toast: true,
          position: 'top-end',
          icon: 'success',
          text: 'Notificación eliminada',
          timer: 1000,
          showConfirmButton: false,
        });
      } catch (error) {
        console.log("error delete->", error)
      }
    },
    async loadMore() {
      this.count++;

      if (this.count > this.pages) this.busy = true;
      else {
        await this.fetchNotifications(this.count);
        this.busy = false;
      };
    },
    async fetchNotifications(page) {
      try {
        const { data } = await dashboard_api.get(`notifications/by_bot_id/${this.selected_bot.id}/${this.user.id}/${page}`);
        let items = data.notifications.map(el => 
          {
            return {
              item: el.notification,
              read: el.read,
              user_id: el.user_id
            }
          }
        );
        console.log("items--->", items);
        this.pages = data.pages;
        this.getNotifications(items);
        this.setGlobalBulletNotification();
      } catch (error) {
        console.error(error);
      }
    },
    setGlobalBulletNotification() {
      let bullet = this.notifications.some(el => el.read == false);
      if (bullet) this.setNotificationBullet(true);
      else this.setNotificationBullet(false);
    }
  },
}
</script>

<style lang="scss" scoped>
.notification-menu {
  position: absolute;
  top: 25px;
  right: .5rem;
  width: 350px;
  height: auto;
  max-height: 500px;
  padding: .5rem;
  border-radius: 1rem;
  box-shadow: 0 0 30px #D8EAFF;
  background-color: #fff;
  z-index: 1000;
  overflow-y: scroll;

  .btn-delete {
    display: none;
    transition: .05s;
  }
  .item {
    position: relative;
    transition: .25s;
    border-radius: .5rem;
    width: 100%;
    padding: 1rem .9rem;

    &:hover {
      background-color: #e8e8e8;

      .btn-delete {
        display: inline-block;
      }
      .btn-time {
        display: none;
      }
    }
    &.read {
      background-color: #f2f2f2;

      &:hover {
        background-color: #e8e8e8;
      }
    }
  }
  .img {
    width: 40px;
    height: 40px;
    border-radius: 5rem;
    background-color: #cdcdcd;
  }
  .custom-height {
    height: 30px;
  }
}
</style>