<template>
  <div
    v-if="video && video.page"
    @mousedown="handleMouseDown"
    :class="{
      video_page: true,
      min_view: minimize,
      'txt-select': is_show
    }"
    :id="minimize ? 'minimize_view-id' : 'left_panel'"
    :ref="minimize ? 'minimize_view' : 'panel'"
  >
    <template v-if="!minimize">
      <div class="video_page-head">
        <div class="label-box">
          <div>
            <h3 class="mb-0" for="">{{ video.page.properties.title.title[0].text.content }}</h3>
          </div>
          <div class="d-flex align-items-center">
            <span @click="minimize = true" class="i-btn i-btn-sm i-sm mr-2">-</span>
            <span @click="$emit('close')" class="i-btn i-close i-btn-sm i-sm mr-0" />
          </div>
        </div>
      </div>
      <div class="video_page-body">
        <div :style="`width: ${width_size}px; height: ${height_size}px; max-width:1000px; max-height: 618px;`" class="mb-5">
          <SpinnerLoading v-show="loading" />
          <iframe
            @load="loading = false"
            v-show="!loading"
            class="iframe_box"
            :src="video.page.properties.url_iframe.url"
            :title="video.page.properties.title.title[0].text.content"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          />
        </div>

        <div v-for="(item, index) in blocks" :key="index" class="blocks">
          <h2 v-if="item.heading_2 && item.heading_2.text.length > 0" class="heading_2 mb-5">{{ item.heading_2.text[0].text.content }}</h2>
          <h3 v-if="item.heading_3 && item.heading_3.text.length > 0" class="mb-5">{{ item.heading_3.text[0].text.content }}</h3>
          <template v-if="item.paragraph && item.paragraph.text.length > 0">
            <a v-if="item.paragraph.text[0].href" :href="item.paragraph.text[0].href" class="d-block text_lg mb-5">{{ item.paragraph.text[0].href }}</a>
            <p v-else-if="item.paragraph.text.length < 1" class="text_lg mb-5">{{ item.paragraph.text[0].text.content }}</p>
            <div v-else class="mb-5">
              <span v-for="(el, index) in item.paragraph.text" :key="index" class="text_lg mb-5">{{ el.text.content }}</span>
            </div>
          </template>
          <p v-if="item.numbered_list_item && item.numbered_list_item.text.length > 0" class="text_lg mb-2">{{ item.numbered_list_item.text[0].text.content }}</p>
          <p v-if="item.bulleted_list_item && item.bulleted_list_item.text.length > 0" class="text_lg mb-2">{{ item.bulleted_list_item.text[0].text.content }}</p>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="p-relative">
        <div class="p-custom">
          <span class="i-btn i-btn-sm i-expand mr-2" @click="minimize = false" />
          <span class="i-btn i-btn-xs i-close" @click="$emit('close')" />
          <!-- <span class="i-btn i-btn-sm mr-0" ref="drag_icon">a</span> -->
        </div>
        <iframe
          @load="loading = false"
          v-show="!loading"
          class="iframe_box"
          :src="video.page.properties.url_iframe.url"
          :title="video.page.properties.title.title[0].text.content"
          frameborder="0"
        />
      </div>
    </template>
  </div>
</template>

<script>
import chatbot_api from "@/chatbot_api";
import { mapState, mapMutations } from "vuex";

export default {
  props: ["video", "is_show"],

  data() {
    return {
      blocks: [],
      show_iframe: false,
      loading: true,
      borderSize: 1,
      mouseX: null,
      minimize: false
    }
  },
  mounted() {
    // Add event listener for mouseup outside component
    document.addEventListener('mouseup', this.handleMouseUp);
  },
  beforeDestroy() {
    // Remove event listener for mouseup
    document.removeEventListener('mouseup', this.handleMouseUp);
  },
  watch: {
    video: {
      deep: true,
      handler(val) {
        this.getInfoPage(val);
      }
    }
  },
  computed: {
    ...mapState(["move_eje_x"]),

    width_size() {
      return this.move_eje_x;
    },
    height_size() {
      return this.width_size / 1.618;
    },
  },
  methods: {
    ...mapMutations(["setMoveEjeX"]),

    handleMouseDown(event) {
      this.borderSize = 5;

      if (event.offsetX < this.borderSize) {
        this.mouseX = event.x;
        document.addEventListener('mousemove', this.handleMouseMove);
      }
    },
    handleMouseMove(event) {
      // console.log("handleMouseDown->", event);

      const dx = this.mouseX - event.x;
      this.mouseX = event.x;
      this.setMoveEjeX((parseInt(window.getComputedStyle(this.$refs.panel).width) + dx) - 64);
      this.$refs.panel.style.width = (parseInt(window.getComputedStyle(this.$refs.panel).width) + dx) + 'px';
    },
    handleMouseUp() {
      document.removeEventListener('mousemove', this.handleMouseMove);
    },
    async getInfoPage(video) {
      let resp = (await chatbot_api.get(`/get_page/${video.id}`)).data.response.results;

      this.blocks = resp;
    }
  }
}
</script>

<style lang="scss" scoped>
.video_page {
  position: absolute;
  top: 0;
  right: 0;
  width: 27.7%;
  min-width: 25%;
  max-width: 1064px;
  background-color: #fff;
  height: 100vh;
  overflow-y: hidden;
  background-color: #fff;
  z-index: 100;
  animation-name: test;
  animation-duration: .25s;
  box-shadow: -10px 0 60px rgba(0, 0, 0, .25);

  @media (max-width: 1600px) {
    width: 35%;
  }
  .bg-spinner {
    position: relative;
    background-color: #e8e8e8;
    border-radius: 1rem;
    width: 100%;
    height: 260px;
    border: 1px solid #cdcdcd;
  }
  &-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2rem;
    width: 100%;
    background-color: #fafafa;
    height: 80px;
    border-bottom: 1px solid #e8e8e8;
  }
  &-body {
    padding: 2rem;
    height: calc(92.25vh);
    overflow-y: scroll;
    background-color: #fff;

    .iframe_box {
      max-width: 1000px;
      max-height: 618px;
      width: 100%;
      height: 100%;
      border-radius: 1rem;
      border: 1px solid #cdcdcd;
      justify-content: center;
      margin: 0 auto;
    }
  }
  @keyframes test {
    0% {
      opacity: 0;
      transform: translateX(100%);
    }
    100% {
      opacity: 1;
      transform: translateX(0);
    }
  }
  .text_lg {
    font-size: 18px;
    line-height: 1.5;
  }
}
.video_page::before {
  content: '';
  background-color: #cdcdcd;
  position: absolute;
  left: 0;
  height: 100%;
  border: 1px solid #cdcdcd;
  cursor: col-resize;
  transition: .25s;
}
.video_page:hover::before {
  border: 5px solid #b2b2b2 !important;
  left: -5px;
}
.min_view {
  right: 1.5rem;
  top: auto;
  bottom: 12%;
  max-width: 300px;
  min-width: 300px;
  width: 300px;
  // max-height: 168px;
  height: 168px;
  background-color: #181818;
  border-radius: 1rem;
  box-shadow: 10px 10px 30px rgba(0, 0, 0, .25);
  padding: .5rem;
  transition: .25s;
  overflow-y: visible;

  &::before {
    content: '';
    background-color: transparent;
    position: absolute;
    left: 0;
    height: 100%;
    border: none;
    cursor: normal;
    transition: .25s;
  }
  .iframe_box {
    max-width: 284px;
    max-height: 152px;
    width: 284px;
    height: 152px;
    border-radius: .5rem;
  }
  .p-custom {
    position: absolute;
    top: -1.9rem;
    right: 0;

    span {
      width: 40px;
      padding: .25rem .9rem;
      background-color: #fff;
    }
  }
}
.min_view:hover::before {
  border: none !important;
  left: 0 !important;
}
</style>